import { Navigate, useRoutes } from "react-router-dom";

//element
import {
  LoginPage,
  OverViewPage,
  MealsPage,
  CreateMealPage,
  EditMeal,
  CategoriesPage,
  CreateCategoriePage,
  EditCategorie,
  CreatePackagesPage,
  PackagesPage,
  EditPackagesPage,
  UsersPage,
  CreateUserPage,
  EditUserPage,
  ClientsPage,
  CreateClientPage,
  EditClientPage,
  PermissionsPage,
  RolesPage,
  CreateRolePage,
  KitchecnPage,
} from "./elements";

import DashboardLayout from "../layouts/dashboard/DashboardLayout";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "/",
      children: [
        {
          element: <Navigate to="auth/login" replace />,
          index: true,
        },
        { path: "auth/login", element: <LoginPage /> },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/overview" replace />, index: true },
        { path: "overview", element: <OverViewPage /> },
        {
          path: "meals",
          children: [
            { path: "", element: <MealsPage /> },
            { path: "create-meal", element: <CreateMealPage /> },
            { path: "edit-meal", element: <EditMeal /> },
          ],
        },

        //categories
        {
          path: "categories",
          children: [
            { path: "", element: <CategoriesPage /> },
            { path: "create-category", element: <CreateCategoriePage /> },
            { path: "edit-category", element: <EditCategorie /> },
          ],
        },

        //packages
        {
          path: "packages",
          children: [
            { path: "", element: <PackagesPage /> },
            { path: "create-package", element: <CreatePackagesPage /> },
            { path: "edit-package", element: <EditPackagesPage /> },
          ],
        },

        //users
        {
          path: "users",
          children: [
            { path: "", element: <UsersPage /> },
            { path: "create-user", element: <CreateUserPage /> },
            { path: "edit-user", element: <EditUserPage /> },
          ],
        },
        {
          path: "customers",
          children: [
            { path: "", element: <ClientsPage /> },
            { path: "create-customer", element: <CreateClientPage /> },
            { path: "edit-client", element: <EditClientPage /> },
          ],
        },
        {
          path: "pemissions",
          children: [{ path: "", element: <PermissionsPage /> }],
        },
        {
          path: "roles",
          children: [
            { path: "", element: <RolesPage /> },
            { path: "create-role", element: <CreateRolePage /> },
          ],
        },

        {
          path: "kitchen",
          children: [{ path: "", element: <KitchecnPage /> }],
        },
      ],
    },
  ]);
}
